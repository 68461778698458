import { Box, Center, Flex, Spinner, useTheme } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import cloudStyles from './cloudCofForm.module.css'
import logotop from '../../../../../../assets/images/vi-busi.png'
//import logo from '../../../../../../assets/images/logo.jpg'
import {
	useLoadAllFormData,
	useLoadRomsFormFieldListData,
} from '../../../../../../hooks/form.hooks'
import { useAuthContext } from '../../../../../../context/auth.context'
import { loadImageBlob } from '../../../../../../utils/aws.util'
import { encode } from '../../cafCrf.util'
import ColoCofTermsConditionsNew from '../../ColoCofTermsConditions'

const CloudCofForm = ({
	formData,
	queueData,
	romsActivity,
	submittedForms,
}) => {
	const [cafData, setCafData] = useState(false)
	const [cafDataupdated, setUpdatedCafData] = useState(null)
	const [acntMngrImgData, setAcntMngrImgData] = useState(null)
	const [imageData, setImageData] = useState(null)
	const [imageType, setImageType] = useState(null)
	const [acctMngSignImageData, setacctMngSignImageData] = useState(null)
	const [tableData, setTableData] = useState([])

	const { mutate } = useLoadRomsFormFieldListData()
	const { mutate: loadAllData } = useLoadAllFormData()
	// const { mutate: loadOrderFormValues } = useLoadFieldValues();
	// const { mutate: loadExcelJsonValues } = useExcelJsonList();
	let componentRef = React.useRef()
	const chakraTheme = useTheme()

	const {
		state: { authData },
	} = useAuthContext()

	const CustomComponent = () => {
		return (
			<div className={cloudStyles.section2}>
				<div className={cloudStyles.font8}>
					<p className={cloudStyles.ligthbg}>
						Commercial Terms Summary( Split up of the line items to be mentioned
						in the BOQ)
					</p>

					<div className={`${cloudStyles.over_hid} ${cloudStyles.referbo}`}>
						<div
							className={`${cloudStyles.margin0} ${cloudStyles.width40} ${cloudStyles.fleft}`}
						>
							One time Charges (Refer BOQ for details*)
						</div>
						<div
							className={`${cloudStyles.marginl20} ${cloudStyles.wid30} ${cloudStyles.fleft} ${cloudStyles.pad_l50}`}
						>
							<input
								readOnly
								className={`${cloudStyles.form_col} ${cloudStyles.inputext_brd_list} ${cloudStyles.text} ${cloudStyles.center}`}
								type='text'
								name=''
								value={cafDataupdated[341040]?.value}
								placeholder=' 0 '
							/>
						</div>

						<div
							className={`${cloudStyles.margin0} ${cloudStyles.wid30} ${cloudStyles.fleft} ${cloudStyles.pad_l50}`}
						>
							&nbsp;
						</div>
					</div>

					<div className={`${cloudStyles.over_hid} ${cloudStyles.referbo}`}>
						<div
							className={`${cloudStyles.margin0} ${cloudStyles.width40} ${cloudStyles.fleft}`}
						>
							Annual Recurring Charges (Refer BOQ for details)*
						</div>
						<div
							className={`${cloudStyles.marginl20} ${cloudStyles.wid30} ${cloudStyles.fleft} ${cloudStyles.pad_l50}`}
						>
							<input
								readOnly
								className={`${cloudStyles.form_col} ${cloudStyles.inputext_brd_list} ${cloudStyles.text} ${cloudStyles.center}`}
								type='text'
								name=''
								value={cafDataupdated[341041]?.value}
								placeholder=' 0 '
							/>
						</div>

						<div
							className={`${cloudStyles.margin0} ${cloudStyles.wid30} ${cloudStyles.fleft} ${cloudStyles.pad_l50}`}
						>
							*Exclusive of tax
						</div>
					</div>
					<p>Comments/ Special Considerations:</p>
					<div className={`${cloudStyles.over_hid} ${cloudStyles.referbo}`}>
						<textarea
							readOnly
							className={`${cloudStyles.form_col} ${cloudStyles.inputext_brd_list} ${cloudStyles.areatext}`}
							placeholder='Comments/ Special Considerations:'
							value={cafDataupdated[340919]?.value}
						></textarea>
					</div>
				</div>
			</div>
		)
	}

	const fetchFieldListData = () => {
		mutate(
			{
				fieldList: {
					organization_id: authData.organization_id,
					account_id: authData.account_id,
					workforce_id: authData.workforce_id,
					form_id: formData.form_id,
				},
				fieldValues: {
					form_id: formData.form_id,
					form_transaction_id: formData.form_transaction_id
						? formData.form_transaction_id
						: formData.data_form_transaction_id,
					start_from: 0,
					limit_value: 50,
				},
			},
			{
				onSuccess: async data => {
					let finalFields = data
					setCafData(false)
					setUpdatedCafData(finalFields)
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}

	const fetchTableData = () => {
		loadAllData(
			{
				workflow_activity_id: queueData?.activity_id,
				form_id: romsActivity?.multi_submission_form_id,
				page_start: 0,
				page_limit: 50,
			},
			{
				onSuccess: async data => {
					if (data.length > 0) {
						let allInlineActivitiesArray = []
						for (const item of data) {
							if (!!item.activity_inline_data)
								allInlineActivitiesArray.push(item.activity_inline_data)
						}
						setTableData(allInlineActivitiesArray)
					}
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}

	useEffect(() => {
		fetchTableData()
		setCafData(true)
		fetchFieldListData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		let data = cafDataupdated
		if (!!data) {
			if (!!data[341023]?.value) {
				// customer company seal
				let custCompanySeal = data[341023]?.value
				loadImageBlob(custCompanySeal, (data, type) => {
					setImageData(data)
					setImageType(type)
				})
			}
			if (!!data[340952]?.value) {
				// manager sign url
				let acctManagerSignUrl = data[340952]?.value
				loadImageBlob(acctManagerSignUrl, (data, type) => {
					setacctMngSignImageData(data)
					setAcntMngrImgData(data[340952]?.value)
				})
			}
		}
	}, [cafDataupdated])

	useEffect(() => {}, [acntMngrImgData])
	let cofContentView = ''
	if (!!cafDataupdated) {
		let customerSignSplit =
			!!cafDataupdated[340947]?.value &&
			cafDataupdated[340947]?.value.length > 0
				? cafDataupdated[340947]?.value.split(' ')
				: ''
		let firstCaps
		let secondBold
		let Signcss
		if (customerSignSplit.length > 0) {
			firstCaps = customerSignSplit['0'].slice(0, 1).toUpperCase()
			secondBold = customerSignSplit['1']
			Signcss = 'signate_img input_sign'
		} else {
			firstCaps = customerSignSplit
			secondBold = ''
			Signcss = 'signate_img input_sign under_line'
		}
		let totalOtc = 0
		let totalArc = 0
		cofContentView = (
			<div className={`${cloudStyles.body} ${cloudStyles.tab_content1}`}>
				<page size='A4'>
					<div className={cloudStyles.section2}>
						<div className={cloudStyles.font8}>
							<div className={cloudStyles.over_hid}>
								<div className={cloudStyles.width63}>
									<p className={cloudStyles.htext1}>
										<img src={logotop} alt='' /> Customer Order Form - CLOUD
									</p>

									<div class=''>
										<div className={`${cloudStyles.flex} ${cloudStyles.jcnt}`}>
											<strong>Company(Customer) Name</strong>
											<input
												readOnly
												className={`${cloudStyles.form_col_input} ${cloudStyles.width60}`}
												type='text'
												name=''
												value={cafDataupdated[340222]?.value}
											/>
										</div>
									</div>
								</div>

								<div
									className={`${cloudStyles.width30} ${cloudStyles.flright}`}
								>
									<div className={cloudStyles.caf}>
										<strong> COF ID </strong>
										<input
											readOnly
											className={cloudStyles.form_col}
											type='text'
											name=''
											value={cafDataupdated[341859]?.value}
										/>
									</div>
									<div className={cloudStyles.caf_inner}>
										<strong> Account Code</strong>
										<input
											readOnly
											className={`${cloudStyles.form_col} ${cloudStyles.no_top}`}
											type='text'
											name=''
											value={cafDataupdated[340223]?.value}
										/>
									</div>
									<div className={cloudStyles.caf_inner}>
										<strong> Cluster: </strong>
										<input
											readOnly
											className={`${cloudStyles.form_col} ${cloudStyles.no_top}`}
											type='text'
											name=''
											value={cafDataupdated[340224]?.value}
										/>
									</div>
								</div>
							</div>

							<div className={cloudStyles.w_85}>
								<p className={cloudStyles.sign_underline}>
									<strong>Authorised Signatory Details</strong>
								</p>
							</div>
							<div
								className={`${cloudStyles.over_hid} ${cloudStyles.auth} ${cloudStyles.brdbtm} ${cloudStyles.send}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width45} ${cloudStyles.flex} ${cloudStyles.send}`}
								>
									Name
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width85}`}
										type='text'
										name=''
										value={cafDataupdated[340227]?.value}
									/>
								</div>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width25} ${cloudStyles.flex} ${cloudStyles.send}`}
								>
									Contact No:
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.w34w}`}
										type='text'
										name=''
										value={cafDataupdated[340228]?.value}
									/>
								</div>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width30} ${cloudStyles.flex} ${cloudStyles.send}`}
								>
									Email:
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width80}`}
										type='text'
										name=''
										value={cafDataupdated[340225]?.value}
									/>
								</div>
							</div>
							<p className={`${cloudStyles.font8}-${cloudStyles.black}`}>
								I /We hereby order for Data Center Cloud Service subject to
								terms and conditions described below and in reference to the
								General Terms and Conditions. We furnish the necessary details
								as follows-
							</p>
							<p className={cloudStyles.ligthbg}>Customer Address</p>

							<p className={`${cloudStyles.auth} ${cloudStyles.jcnt}`}>
								Primary Contact Name
								<input
									readOnly
									className={`${cloudStyles.form_col} ${cloudStyles.width75}`}
									type='text'
									name=''
									value={cafDataupdated[340230]?.value}
								/>
							</p>
							<div className={`${cloudStyles.auth} ${cloudStyles.over_hid}`}>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width50} ${cloudStyles.flex} ${cloudStyles.jcnt}`}
								>
									Designation
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width50}`}
										type='text'
										name=''
										value={cafDataupdated[340231]?.value}
									/>
								</div>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width50} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.padl30}`}
								>
									Dept. Name
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width70}`}
										type='text'
										name=''
										value={cafDataupdated[340232]?.value}
									/>
								</div>
							</div>

							<div
								className={`${cloudStyles.auth} ${cloudStyles.margin0} ${cloudStyles.jcnt} `}
							>
								<span className={cloudStyles.width54px}>Address</span>
								<input
									readOnly
									className={`${cloudStyles.form_col} ${cloudStyles.width75}`}
									type='text'
									name=''
									value={cafDataupdated[340233]?.value}
								/>
							</div>
							<div
								className={`${cloudStyles.auth} ${cloudStyles.margin0} ${cloudStyles.jcnt} ${cloudStyles.paddingt10}`}
							>
								<span className={cloudStyles.width54px}>
									City/Village/Post office/State
								</span>
								<input
									readOnly
									className={`${cloudStyles.form_col} ${cloudStyles.width75}`}
									type='text'
									name=''
									value={cafDataupdated[340234]?.value}
								/>
							</div>
							<div className={`${cloudStyles.auth} ${cloudStyles.over_hid}`}>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width50} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.margint10}`}
								>
									Pincode
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width50}`}
										type='text'
										name=''
										value={cafDataupdated[340235]?.value}
									/>
								</div>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width50} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.padl30}`}
								>
									Telephone
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width70}`}
										type='text'
										name=''
										value={cafDataupdated[340236]?.value}
									/>
								</div>
							</div>

							<div
								className={`${cloudStyles.auth} ${cloudStyles.over_hid} ${cloudStyles.brdbonly}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width58} ${cloudStyles.flex} ${cloudStyles.send} ${cloudStyles.jcnt}`}
								>
									Landmark
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width59}`}
										type='text'
										name=''
										value={cafDataupdated[340237]?.value}
									/>
								</div>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width42} ${cloudStyles.flex} ${cloudStyles.send} ${cloudStyles.jcnt}`}
								>
									<span className={cloudStyles.faxn}>Fax No.</span>
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width66}`}
										type='text'
										name=''
										value={cafDataupdated[340238]?.value}
									/>
								</div>
							</div>
							<div className={`${cloudStyles.auth} ${cloudStyles.over_hid}`}>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width58} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.brdbonly}`}
								>
									Contact Email Id
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width59}`}
										type='text'
										name=''
										value={cafDataupdated[340239]?.value}
									/>
								</div>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width42} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.padl30}`}
								>
									Mobile No / Alt No.
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width49}`}
										type='text'
										name=''
										value={cafDataupdated[340240]?.value}
									/>
								</div>
							</div>
							<p className={cloudStyles.ligthbg}>
								Customer Technical(key) Contact Details
							</p>

							<p className={`${cloudStyles.auth} ${cloudStyles.jcnt}`}>
								Primary Contact Name
								<input
									readOnly
									className={`${cloudStyles.form_col} ${cloudStyles.width75}`}
									type='text'
									name=''
									value={cafDataupdated[340242]?.value}
								/>
							</p>
							<div className={`${cloudStyles.auth} ${cloudStyles.over_hid}`}>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width50} ${cloudStyles.flex} ${cloudStyles.jcnt}`}
								>
									Designation
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width50}`}
										type='text'
										name=''
										value={cafDataupdated[340886]?.value}
									/>
								</div>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width50} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.padl30}`}
								>
									Dept. Name
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width70}`}
										type='text'
										name=''
										value={cafDataupdated[340887]?.value}
									/>
								</div>
							</div>

							<div
								className={`${cloudStyles.auth} ${cloudStyles.margin0} ${cloudStyles.jcnt}`}
							>
								<span className={cloudStyles.width54px}>Address</span>
								<input
									readOnly
									className={`${cloudStyles.form_col} ${cloudStyles.width75}`}
									type='text'
									name=''
									value={cafDataupdated[340888]?.value}
								/>
							</div>
							<div
								className={`${cloudStyles.auth} ${cloudStyles.pad_b5} ${cloudStyles.jcnt}`}
							>
								<span className={cloudStyles.width42px}>Telephone </span>
								<input
									readOnly
									className={`${cloudStyles.form_col} ${cloudStyles.width75}`}
									type='text'
									name=''
									value={cafDataupdated[340889]?.value}
								/>
							</div>

							<div className={`${cloudStyles.auth} ${cloudStyles.over_hid}`}>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width58} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.brdbonly}`}
								>
									Contact Email Id
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width59}`}
										type='text'
										name=''
										value={cafDataupdated[340890]?.value}
									/>
								</div>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width42} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.padl30}`}
								>
									Mobile No / Alt No.
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width49}`}
										type='text'
										name=''
										value={cafDataupdated[340891]?.value}
									/>
								</div>
							</div>
							<div className={cloudStyles.signateNew}>
								{firstCaps !== '' ? (
									<div style={{ padding: '0px' }} className={Signcss}>
										{firstCaps + '  .  '}
										<span className='under_line'>{secondBold}</span>
									</div>
								) : (
									''
								)}
								{imageData !== null && imageData !== '' ? (
									<div style={{ padding: '0px' }} className='signate_img'>
										<img
											src={
												imageData !== null
													? 'data:' + imageType + ';base64,' + encode(imageData)
													: ''
											}
											alt={'Company Seal'}
										/>
									</div>
								) : (
									''
								)}
							</div>
						</div>
					</div>
				</page>

				<page size='A4'>
					<div className={cloudStyles.section2}>
						<div className={cloudStyles.font8}>
							<p className={cloudStyles.ligthbg}>CUSTOMER TYPE</p>
							<div
								className={`${cloudStyles.check} ${cloudStyles.flex} ${cloudStyles.ctyi} ${cloudStyles.f12}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width18} ${cloudStyles.pad_7t}`}
								>
									Customer Type
								</div>

								<div className={cloudStyles.ctinf}>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width14}`}
									>
										{cafDataupdated[340893]?.value === 'SA' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										SA
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width18}`}
									>
										{cafDataupdated[340893]?.value === 'SOHO' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										SOHO
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width20}`}
									>
										{cafDataupdated[340893]?.value === 'SME' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										SME
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width14}`}
									>
										{cafDataupdated[340893]?.value === 'Govt' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Govt
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width14}`}
									>
										{cafDataupdated[340893]?.value === 'Carrier' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Carrier
									</div>
								</div>
							</div>
							<div
								className={`${cloudStyles.check} ${cloudStyles.flex} ${cloudStyles.custype} ${cloudStyles.f12}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width18} ${cloudStyles.pad_7t}`}
								>
									Customer Vertical Type
								</div>

								<div className={cloudStyles.ctinf}>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width14} ${cloudStyles.f12}`}
									>
										{cafDataupdated[340894]?.value === 'Automotive' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Automotive
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width18}`}
									>
										{cafDataupdated[340894]?.value === 'Consumer Goods' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Consumer Goods
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width20} `}
									>
										{cafDataupdated[340894]?.value ===
										'Diversified & Projects' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Diversified & Projects
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width14}`}
									>
										{cafDataupdated[340894]?.value === 'Education' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Education
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width14}`}
									>
										{cafDataupdated[340894]?.value === 'Engineering' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Engineering
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width14}`}
									>
										{cafDataupdated[340894]?.value === 'Retail' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Retail
									</div>
								</div>
							</div>
							<div
								className={`${cloudStyles.check} ${cloudStyles.flex} ${cloudStyles.custype} ${cloudStyles.f12}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width18} ${cloudStyles.pad_7t}`}
								>
									{' '}
								</div>

								<div className={cloudStyles.ctinf}>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width14}`}
									>
										{cafDataupdated[340894]?.value === 'Health Care' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Health Care
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width18}`}
									>
										{cafDataupdated[340894]?.value === 'Services' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Services
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width20} `}
									>
										{cafDataupdated[340894]?.value === 'IT & Telecom' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										IT & Telecom
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width14}`}
									>
										{cafDataupdated[340894]?.value === 'Logistics' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Logistics
									</div>

									<div className={cloudStyles.fleft}>
										{cafDataupdated[340894]?.value ===
										'Manufacturing & Processing' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Manufacturing & Processing
									</div>
								</div>
							</div>
							<div
								className={`${cloudStyles.check} ${cloudStyles.flex} ${cloudStyles.custype} ${cloudStyles.f12}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width18} ${cloudStyles.pad_7t}`}
								>
									{' '}
								</div>

								<div className={cloudStyles.ctinf}>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width14}`}
									>
										{cafDataupdated[340894]?.value === 'Trading' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Trading
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width18}`}
									>
										{cafDataupdated[340894]?.value === 'Projects EPC' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Projects EPC
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width20}`}
									>
										{cafDataupdated[340894]?.value ===
										'Industry Associations' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Industry Associations
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width14}`}
									>
										{cafDataupdated[340894]?.value === 'Finance' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Finance
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width14}`}
									>
										{cafDataupdated[340894]?.value === 'Others' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Others
									</div>
								</div>
							</div>

							<p className={cloudStyles.ligthbg}>Billing Address</p>
							{/* <p className={cloudStyles.margin0}>This Address is for billing</p> */}

							<p className={`${cloudStyles.auth} ${cloudStyles.jcnt}`}>
								Contact Person Name
								<input
									readOnly
									className={`${cloudStyles.form_col} ${cloudStyles.width75}`}
									type='text'
									name=''
									value={cafDataupdated[340897]?.value}
								/>
							</p>
							<div className={`${cloudStyles.auth} ${cloudStyles.over_hid}`}>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width50} ${cloudStyles.flex} ${cloudStyles.jcnt}`}
								>
									Designation
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width50}`}
										type='text'
										name=''
										value={cafDataupdated[340898]?.value}
									/>
								</div>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width50} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.padl30}`}
								>
									Dept. Name
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width70}`}
										type='text'
										name=''
										value={cafDataupdated[340899]?.value}
									/>
								</div>
							</div>

							<div
								className={`${cloudStyles.auth} ${cloudStyles.margin0} ${cloudStyles.jcnt}`}
							>
								<span className={cloudStyles.width54px}>Address</span>
								<input
									readOnly
									className={`${cloudStyles.form_col} ${cloudStyles.width75}`}
									type='text'
									name=''
									value={cafDataupdated[340900]?.value}
								/>
							</div>
							<div
								className={`${cloudStyles.auth} ${cloudStyles.margin0} ${cloudStyles.jcnt} ${cloudStyles.paddingt10}`}
							>
								<span className={cloudStyles.width54px}>
									City/Village/Post office/State
								</span>
								<input
									readOnly
									className={`${cloudStyles.form_col} ${cloudStyles.width75}`}
									type='text'
									name=''
									value={cafDataupdated[340901]?.value}
								/>
							</div>

							<div className={`${cloudStyles.auth} ${cloudStyles.over_hid}`}>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width50} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.margint10}`}
								>
									Pincode
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width50}`}
										type='text'
										name=''
										value={cafDataupdated[340902]?.value}
									/>
								</div>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width50} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.padl30}`}
								>
									Telephone
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width70}`}
										type='text'
										name=''
										value={cafDataupdated[340903]?.value}
									/>
								</div>
							</div>

							<div
								className={`${cloudStyles.auth} ${cloudStyles.over_hid} ${cloudStyles.brdbonly}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width58} ${cloudStyles.flex} ${cloudStyles.send} ${cloudStyles.jcnt}`}
								>
									Landmark
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width59}`}
										type='text'
										name=''
										value={cafDataupdated[341000]?.value}
									/>
								</div>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width42} ${cloudStyles.flex} ${cloudStyles.send} ${cloudStyles.jcnt}`}
								>
									<span className={cloudStyles.faxn}>Fax No.</span>
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width66}`}
										type='text'
										name=''
										value={cafDataupdated[340904]?.value}
									/>
								</div>
							</div>
							<div className={`${cloudStyles.auth} ${cloudStyles.over_hid}`}>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width58} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.brdbonly}`}
								>
									Contact Email Id
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width59}`}
										type='text'
										name=''
										value={cafDataupdated[340905]?.value}
									/>
								</div>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width42} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.padl30}`}
								>
									Mobile No / Alt No.
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width49}`}
										type='text'
										name=''
										value={cafDataupdated[340906]?.value}
									/>
								</div>
							</div>

							<div
								className={`${cloudStyles.auth} ${cloudStyles.flex} ${cloudStyles.over_hid}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.width40}`}
								>
									PAN NUMBER
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width50}`}
										type='text'
										name=''
										value={cafDataupdated[340907]?.value}
									/>
								</div>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.width40}`}
								>
									TAN NUMBER
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width50}`}
										type='text'
										name=''
										value={cafDataupdated[344156]?.value}
									/>
								</div>
							</div>

							<div
								className={`${cloudStyles.auth} ${cloudStyles.flex} ${cloudStyles.over_hid}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.gstbx} ${cloudStyles.check}`}
								>
									<div className={cloudStyles.fleft}>GST TYPE</div>

									<div
										className={`${cloudStyles.fleft} ${cloudStyles.pad_l_r10}`}
										style={{ marginLeft: '80px' }}
									>
										{cafDataupdated[340908]?.value === 'Normal GSTIN' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Normal GSTIN
									</div>
									<div className={cloudStyles.fleft}>
										{cafDataupdated[340908]?.value === 'SEZ' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										SEZ
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.pad_l_r10}`}
									>
										{cafDataupdated[340908]?.value ===
										'Not resgistered under GST' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Not resgistered under GST
									</div>
								</div>
							</div>
							<div
								className={`${cloudStyles.margin0} ${cloudStyles.auth} ${cloudStyles.over_hid}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.flex} ${cloudStyles.jcnt} ${cloudStyles.width40}`}
								>
									GST Registration No.
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width50}`}
										type='text'
										name=''
										value={cafDataupdated[340909]?.value}
									/>
								</div>
							</div>

							<div
								className={`${cloudStyles.auth} ${cloudStyles.flex} ${cloudStyles.over_hid}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.gstbx} ${cloudStyles.check}`}
									style={{ marginTop: '10px' }}
								>
									<div className={cloudStyles.fleft}>Partner</div>

									<div
										className={`${cloudStyles.fleft} ${cloudStyles.pad_l_r10}`}
										style={{ marginLeft: '95px' }}
									>
										{cafDataupdated[344157]?.value === 'Ctrls' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Ctrls
									</div>
									<div className={cloudStyles.fleft}>
										{cafDataupdated[344157]?.value === 'Webwerks' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Webwerks
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.pad_l_r10}`}
									>
										{cafDataupdated[344157]?.value === 'Yotta' ? (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cloudStyles.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Yotta
									</div>
								</div>
							</div>
							<p className={cloudStyles.ligthbg}>
								Deployment location(DC Location)
							</p>

							<div
								className={`${cloudStyles.check} ${cloudStyles.over_hid} ${cloudStyles.tcheck} ${cloudStyles.font9}`}
							>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width20}`}>
									{cafDataupdated[344165]?.value?.toUpperCase() ===
									'MUMBAI - MAHARASHTRA' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Mumbai -Maharashtra
								</div>

								<div className={`${cloudStyles.fleft} ${cloudStyles.width20}`}>
									{cafDataupdated[344165]?.value?.toUpperCase() ===
									'BANGLORE - KARNATAKA' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Banglore - Karnataka
								</div>

								<div className={`${cloudStyles.fleft} ${cloudStyles.width20}`}>
									{cafDataupdated[344165]?.value?.toUpperCase() ===
									'HYDERABAD - AP & TELANGANA' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Hyderabad-AP&Telanana
								</div>

								<div className={`${cloudStyles.fleft} ${cloudStyles.width20}`}>
									{cafDataupdated[344165]?.value?.toUpperCase() ===
									'NOIDA- UTTAR PRADESH' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Noida- Uttar Pradesh
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width20}`}>
									{cafDataupdated[344165]?.value?.toUpperCase() ===
									'PUNE- MAHARASHTRA' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Pune- Maharashtra
								</div>
							</div>
							<div
								className={`${cloudStyles.check} ${cloudStyles.over_hid} ${cloudStyles.tcheck} ${cloudStyles.font9}`}
							>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width20}`}>
									{cafDataupdated[344165]?.value?.toUpperCase() ===
									'NAVI MUMBAI-MAHARASHTRA' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Navi Mumbai-Maharashtra
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width20}`}>
									{cafDataupdated[344165]?.value?.toUpperCase() ===
									'AHMEDABAD- GUJARAT' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Ahmedabad- Gujarat
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width20}`}>
									{cafDataupdated[344165]?.value?.toUpperCase() ===
									'CHENNAI - TAMILNADU' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Chennai - Tamilnadu
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width20}`}>
									{cafDataupdated[344165]?.value?.toUpperCase() ===
									'KOLKATA - WEST BENGAL' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Kolkata - West Bengal
								</div>
							</div>
							<p className={cloudStyles.ligthbg}>
								CLOUD / CLOUD MANAGED SERVICES ORDER DETAILS
							</p>
							<div
								className={`${cloudStyles.check} ${cloudStyles.over_hid} ${cloudStyles.tcheck}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width18} ${cloudStyles.pad_7t}`}
								>
									Product Category
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width16}`}>
									{cafDataupdated[344158]?.value === 'IaaS' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									IaaS
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width20}`}>
									{cafDataupdated[344158]?.value ===
									'Cloud Managed Services' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Cloud Managed Services
								</div>
							</div>
							<div
								className={`${cloudStyles.check} ${cloudStyles.over_hid} ${cloudStyles.tcheck}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width18} ${cloudStyles.pad_7t}`}
								>
									Type of Order
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width16}`}>
									{cafDataupdated[340911]?.value === 'New' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									New
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width16}`}>
									{cafDataupdated[340911]?.value === 'Change' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Change
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width16}`}>
									{cafDataupdated[340911]?.value === 'Demo - Paid' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Demo - Paid
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width16}`}>
									{cafDataupdated[340911]?.value === 'Demo - Free' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Demo - Free
								</div>
							</div>

							<div
								className={`${cloudStyles.check} ${cloudStyles.over_hid} ${cloudStyles.tcheck}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width18} ${cloudStyles.pad_7t}`}
								>
									Contract(Lock in)Period
								</div>
								<div>
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.no_top}`}
										type='text'
										name=''
										value={cafDataupdated[340912]?.value}
										style={{ width: '30%' }}
									/>
									<span className={cloudStyles.ml2px}>Months</span>
								</div>
							</div>
							<div
								className={`${cloudStyles.check} ${cloudStyles.over_hid} ${cloudStyles.tcheck}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width18} ${cloudStyles.pad_7t}`}
								>
									Multi year PO
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width16}`}>
									{cafDataupdated[344159]?.value?.toUpperCase() === 'YES' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Yes
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width16}`}>
									{cafDataupdated[344159]?.value?.toUpperCase() === 'NO' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									No
								</div>
							</div>
							<div
								className={`${cloudStyles.check} ${cloudStyles.over_hid} ${cloudStyles.tcheck}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width18} ${cloudStyles.pad_7t}`}
								></div>

								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width16} ${cloudStyles.yoytable}`}
								>
									<table>
										<tr>
											<td>No. of year</td> <td>YOY%</td>
										</tr>
										<tr>
											<td>Year 1</td>
											<td>{cafDataupdated[344160]?.value}</td>
										</tr>
										<tr>
											<td>Year 2</td> <td>{cafDataupdated[344161]?.value}</td>
										</tr>
										<tr>
											<td>Year 3</td> <td>{cafDataupdated[344162]?.value}</td>
										</tr>
										<tr>
											<td>Year 4</td> <td>{cafDataupdated[344163]?.value}</td>
										</tr>
										<tr>
											<td>Year 5</td>
											<td>{cafDataupdated[344164]?.value}</td>
										</tr>
									</table>
								</div>
							</div>
							<div
								className={`${cloudStyles.check} ${cloudStyles.over_hid} ${cloudStyles.tcheck}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width18} ${cloudStyles.pad_7t}`}
								>
									Billing Periodicity
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width16}`}>
									{cafDataupdated[340913]?.value === 'Monthly' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Monthly
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width16}`}>
									{cafDataupdated[340913]?.value === 'Quarterly' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Quarterly{' '}
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width16}`}>
									{cafDataupdated[340913]?.value === 'Half Yearly' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Half Yearly{' '}
								</div>

								<div className={`${cloudStyles.fleft} ${cloudStyles.width16}`}>
									{cafDataupdated[340913]?.value === 'Annually' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Annually{' '}
								</div>
							</div>
							<div
								className={`${cloudStyles.check} ${cloudStyles.over_hid} ${cloudStyles.tcheck}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width18} ${cloudStyles.pad_7t}`}
								>
									{' '}
									Billing in{' '}
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width16}`}>
									{cafDataupdated[340914]?.value === 'Advance' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Advance{' '}
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width16}`}>
									{cafDataupdated[340914]?.value === 'Arrear' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									Arrear
								</div>
							</div>

							<div
								className={`${cloudStyles.check} ${cloudStyles.over_hid} ${cloudStyles.tcheck}`}
							>
								<div
									className={`${cloudStyles.fleft} ${cloudStyles.width18} ${cloudStyles.pad_7t}`}
								>
									Currency
								</div>
								<div className={`${cloudStyles.fleft} ${cloudStyles.width16}`}>
									{cafDataupdated[340915]?.value === 'INR' ? (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											checked
										/>
									) : (
										<input
											className={cloudStyles.form_col}
											type='checkbox'
											name=''
											value=''
											disabled
										/>
									)}
									INR
								</div>
							</div>
							<div className={cloudStyles.signateNew}>
								{firstCaps !== '' ? (
									<div style={{ padding: '0px' }} className={Signcss}>
										{firstCaps + '  .  '}
										<span className='under_line'>{secondBold}</span>
									</div>
								) : (
									''
								)}
								{imageData !== null && imageData !== '' ? (
									<div style={{ padding: '0px' }} className='signate_img'>
										<img
											src={
												imageData !== null
													? 'data:' + imageType + ';base64,' + encode(imageData)
													: ''
											}
											alt={'Company Seal'}
										/>
									</div>
								) : (
									''
								)}
							</div>
						</div>
					</div>
				</page>

				{/*<page size='A4'>
					<div className={cloudStyles.section2}>
						<div className={cloudStyles.font8}>
							<p className={cloudStyles.ligthbg}>
								Commercial Terms Summary( Split up of the line items to be
								mentioned in the BOQ)
							</p>

							<div className={`${cloudStyles.over_hid} ${cloudStyles.referbo}`}>
								<div
									className={`${cloudStyles.margin0} ${cloudStyles.width40} ${cloudStyles.fleft}`}
								>
									One time Charges (Refer BOQ for details*)
								</div>
								<div
									className={`${cloudStyles.marginl20} ${cloudStyles.wid30} ${cloudStyles.fleft} ${cloudStyles.pad_l50}`}
								>
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.inputext_brd_list} ${cloudStyles.text} ${cloudStyles.center}`}
										type='text'
										name=''
										value={cafDataupdated[341040]?.value}
										placeholder=' 0 '
									/>
								</div>

								<div
									className={`${cloudStyles.margin0} ${cloudStyles.wid30} ${cloudStyles.fleft} ${cloudStyles.pad_l50}`}
								>
									&nbsp;
								</div>
							</div>

							<div className={`${cloudStyles.over_hid} ${cloudStyles.referbo}`}>
								<div
									className={`${cloudStyles.margin0} ${cloudStyles.width40} ${cloudStyles.fleft}`}
								>
									Annual Recurring Charges (Refer BOQ for details)*
								</div>
								<div
									className={`${cloudStyles.marginl20} ${cloudStyles.wid30} ${cloudStyles.fleft} ${cloudStyles.pad_l50}`}
								>
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.inputext_brd_list} ${cloudStyles.text} ${cloudStyles.center}`}
										type='text'
										name=''
										value={cafDataupdated[341041]?.value}
										placeholder=' 0 '
									/>
								</div>

								<div
									className={`${cloudStyles.margin0} ${cloudStyles.wid30} ${cloudStyles.fleft} ${cloudStyles.pad_l50}`}
								>
									*Exclusive of tax
								</div>
							</div>
							<p>Comments/ Special Considerations:</p>
							<div className={`${cloudStyles.over_hid} ${cloudStyles.referbo}`}>
								<textarea
									readOnly
									className={`${cloudStyles.form_col} ${cloudStyles.inputext_brd_list} ${cloudStyles.areatext}`}
									placeholder='Comments/ Special Considerations:'
									value={cafDataupdated[340919]?.value}
								></textarea>
							</div>

							<div className={cloudStyles.pad_b5}>
								<p className={cloudStyles.ligthbg}>Terms and Conditions</p>
							</div>

							<div className={cloudStyles.terns}>
								a.) Customer is responsible for conducting the UAT and provide
								service acceptance within 2 days post receipt of delivery
								confirmation from VITSL. <br /> <br />
								b) VITSL reserves the right to cancel the services or start
								billing of the the service in case of service acceptance not
								received from customer within 5 days of delivery. <br /> <br />
								c) Standard Payment Terms: Quarterly in advance <br />
								<br />
								d) Early Termination charges: If customer terminates the service
								prior to the end of the committed contract(Lock in) period (as
								mentioned in the COF) then customer shall be liable to pay 100%
								early termination charges for the remaining period of the
								contract. <br /> <br />
								e) For any reason if services are suspended on account of
								customer related issues, VITSL will continue billing for the
								suspended period and customer is liable to pay for the
								suspension period <br /> <br />
								f) Customer should provide a minimum 30 days notice period
								before terminating entire/any part of the service <br /> <br />
								g) In the event the term or usage of a service extends beyond
								the agreed expiry date or termination date of this COF, then the
								term and conditions of this COF shall be extended to the
								expiration /termination of the applicable Link. Customer shall
								be bound to make payment of Link during the extended period as
								per last agreed pricing terms. Either Party shall have a right
								to terminate a service after giving prior written notice of
								thirty (30) days’ notice in advance. <br /> <br />
								h) Cloud services wil have a 10% YoY price increase applicable
								by default
								<br /> <br />
								<strong> Note- </strong> In case the billing address is not in
								the place of supply(where the data center services are
								implemented), the customer will not be able to avail GST credit
							</div>
							<div className={cloudStyles.signateNew}>
								{firstCaps !== '' ? (
									<div style={{ padding: '0px' }} className={Signcss}>
										{firstCaps + '  .  '}
										<span className='under_line'>{secondBold}</span>
									</div>
								) : (
									''
								)}
								{imageData !== null && imageData !== '' ? (
									<div style={{ padding: '0px' }} className='signate_img'>
										<img
											src={
												imageData !== null
													? 'data:' + imageType + ';base64,' + encode(imageData)
													: ''
											}
											alt={'Company Seal'}
										/>
									</div>
								) : (
									''
								)}
							</div>
						</div>
					</div>
								</page>*/}

				<page size='A4'>
					<CustomComponent />
					<div className={`${cloudStyles.section2} ${cloudStyles.font8}`}>
						<div class=''></div>
						<div
							className={`${cloudStyles.over_hid} ${cloudStyles.pad_t20} ${cloudStyles.apsign}`}
						>
							<div
								className={`${cloudStyles.fleft} ${cloudStyles.width58p} ${cloudStyles.pad_t10}`}
							>
								<p className={cloudStyles.marb5}>
									Applicant's Name / Authorised Signatory's Name
								</p>
								<p className={cloudStyles.margin0}>
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width97p} ${cloudStyles.inputext}`}
										type='text'
										name=''
										value={cafDataupdated[340921]?.value}
									/>
								</p>
								<div className={`${cloudStyles.flex} ${cloudStyles.pad_t30}`}>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width40}`}
									>
										Designation <br />
										<input
											readOnly
											className={`${cloudStyles.form_col} ${cloudStyles.width97p} ${cloudStyles.inputext} ${cloudStyles.design}`}
											type='text'
											name=''
											value={cafDataupdated[340922]?.value}
										/>
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width30}`}
									>
										Place <br />
										<input
											readOnly
											className={`${cloudStyles.form_col} ${cloudStyles.width97p} ${cloudStyles.inputext} ${cloudStyles.design}`}
											type='text'
											name=''
											value={cafDataupdated[340923]?.value}
										/>
									</div>
									<div
										className={`${cloudStyles.fleft} ${cloudStyles.width30}`}
									>
										Date <br />
										<input
											readOnly
											className={`${cloudStyles.form_col} ${cloudStyles.width97p} ${cloudStyles.inputext1} ${cloudStyles.design}`}
											type='text'
											name=''
											value={cafDataupdated[340924]?.value}
										/>
									</div>
								</div>
							</div>
							<div className={`${cloudStyles.flright} ${cloudStyles.width40p}`}>
								<div className={cloudStyles.signates}>
									{firstCaps !== '' ? (
										<div className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									Signature of the Customer / Authorised signatory within this
									box only (In case of institutional, please affix
									office/company seal){' '}
								</div>
							</div>
						</div>
						<div className={`${cloudStyles.over_hid} ${cloudStyles.pad_t30}`}>
							<div
								className={`${cloudStyles.fleft} ${cloudStyles.width58p} ${cloudStyles.pad_t10}`}
							>
								<p className={cloudStyles.marb5}>Account Manager Name</p>
								<p className={cloudStyles.margin0}>
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width97p} ${cloudStyles.inputext}`}
										type='text'
										name=''
										value={cafDataupdated[340948]?.value}
									/>
								</p>
								<p className={cloudStyles.marb5}>
									Account Manager Cluster Office{' '}
								</p>
								<p className={cloudStyles.margin0}>
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width50} ${cloudStyles.inputext_brd_list}`}
										type='text'
										name=''
										value={cafDataupdated[340949]?.value}
									/>
								</p>
								<p className={cloudStyles.marb5}>Channel Partner Name</p>
								<p className={cloudStyles.margin0}>
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width97p} ${cloudStyles.inputext}`}
										type='text'
										name=''
										value={cafDataupdated[340950]?.value}
									/>
								</p>
								<p className={cloudStyles.marb5}>Channel Partner Code</p>
								<p className={cloudStyles.margin0}>
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width50} ${cloudStyles.inputext_brd_list}`}
										type='text'
										name=''
										value={cafDataupdated[340951]?.value}
									/>
								</p>
							</div>
							<div
								className={`${cloudStyles.flright} ${cloudStyles.width40p} ${cloudStyles.signrigt}`}
							>
								<div className={cloudStyles.signate_acnt_mngr}>
									<div className={cloudStyles.sign_img}>
										{acctMngSignImageData !== null &&
										acctMngSignImageData !== '' ? (
											<img
												src={
													acctMngSignImageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(acctMngSignImageData)
														: ''
												}
												alt={'Account Manager Sign'}
												style={{
													height: '80px',
													width: '100px',
													marginBottom: '10px',
												}}
											/>
										) : (
											''
										)}
									</div>
									Signature of the Account Manager{' '}
								</div>
							</div>
						</div>

						<div className={cloudStyles.limite}>
							Vodafone Idea Technology Solutions limited (VITSL){' '}
						</div>

						<div className={cloudStyles.signateNew}>
							{firstCaps !== '' ? (
								<div style={{ padding: '0px' }} className={Signcss}>
									{firstCaps + '  .  '}
									<span className='under_line'>{secondBold}</span>
								</div>
							) : (
								''
							)}
							{imageData !== null && imageData !== '' ? (
								<div style={{ padding: '0px' }} className='signate_img'>
									<img
										src={
											imageData !== null
												? 'data:' + imageType + ';base64,' + encode(imageData)
												: ''
										}
										alt={'Company Seal'}
									/>
								</div>
							) : (
								''
							)}
						</div>
					</div>
				</page>

				<page size='A4'>
					<div className={cloudStyles.section2}>
						<div className={cloudStyles.font8}>
							<div className={cloudStyles.logrow}>
								<div className={cloudStyles.loimg}>
									<img src={logotop} alt='' />
								</div>
								<div className={cloudStyles.lotxt}>
									BOQ- Cloud Managed Services
								</div>
							</div>

							<div className={cloudStyles.cmname}>
								<p>Customer Name: </p>
								<input
									readOnly
									className={`${cloudStyles.form_col} ${cloudStyles.width97p} ${cloudStyles.inputext}`}
									type='text'
									name=''
									value={cafDataupdated[341030]?.value}
									placeholder='Customer Name: '
								/>
							</div>

							<div className={cloudStyles.boqrow}>
								<div className={cloudStyles.boql}>
									<p>Customer Billing Address:</p>
									<textarea
										name=''
										id=''
										className={cloudStyles.form_col}
										placeholder='Customer  Billing Address:'
										value={cafDataupdated[341031]?.value}
									></textarea>
									<p>Customer Contact :</p>
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width97p} ${cloudStyles.inputext}`}
										type='text'
										name=''
										value={cafDataupdated[341033]?.value}
										placeholder='Customer Contact :'
									/>
									<p className={` ${cloudStyles.margint20}`}>
										Billing Frequency :
									</p>
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width97p} ${cloudStyles.inputext}`}
										type='text'
										name=''
										value={cafDataupdated[341034]?.value}
										placeholder='Billing Frequency :'
									/>
								</div>
								<div className={cloudStyles.boqr}>
									<p>Ship to address:</p>
									<textarea
										name=''
										id=''
										className={cloudStyles.form_col}
										placeholder='Ship to address'
										value={cafDataupdated[341032]?.value}
									></textarea>
									<p>GST no :</p>
									<input
										readOnly
										className={`${cloudStyles.form_col} ${cloudStyles.width97p} ${cloudStyles.inputext}`}
										type='text'
										name=''
										value={cafDataupdated[341036]?.value}
										placeholder='GST no :'
									/>
								</div>
							</div>

							<div className={cloudStyles.bbqtable}>
								<table>
									<tbody>
										<tr>
											{' '}
											<th>Sl No</th>
											<th>Cloud Items</th>
											<th>Quantity</th>
											<th>One time Charges (OTC)</th>
											<th>Annual Recurring Charges(ARC)</th>
										</tr>

										{tableData.length > 0 &&
											tableData.map((row, idx) => {
												totalOtc =
													parseFloat(totalOtc) + parseFloat(row[4]?.field_value)
												totalArc =
													parseFloat(totalArc) + parseFloat(row[5]?.field_value)
												return (
													<tr key={idx}>
														<td>{idx + 1}</td>
														<td>{row[2]?.field_value}</td>
														<td>{row[3]?.field_value}</td>
														<td>{row[4]?.field_value}</td>
														<td>{row[5]?.field_value}</td>
													</tr>
												)
											})}
										{tableData.length > 0 && (
											<tr>
												<td colSpan={3} align='right'>
													Total
												</td>
												<td>{totalOtc.toFixed(2)}</td>
												<td>{totalArc.toFixed(2)}</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>

							<div className={cloudStyles.btmtxt}>
								<div className={cloudStyles.btleft}>
									<p style={{ color: 'black' }}>
										{firstCaps !== '' ? (
											<div style={{ padding: '0px' }} className={Signcss}>
												{firstCaps + '  .  '}
												<span className='under_line'>{secondBold}</span>
											</div>
										) : (
											''
										)}
									</p>
									<p>Signature of the customer</p>
									<p>(Authorised Signatory)</p>
								</div>
								<div className={cloudStyles.btright}>
									<p>
										Date{' '}
										<span style={{ color: 'black' }}>
											{' '}
											&nbsp;{cafDataupdated[340924]?.value}
										</span>
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cloudStyles.signateNew}>
						{firstCaps !== '' ? (
							<div style={{ padding: '0px' }} className={Signcss}>
								{firstCaps + '  .  '}
								<span className='under_line'>{secondBold}</span>
							</div>
						) : (
							''
						)}
						{imageData !== null && imageData !== '' ? (
							<div style={{ padding: '0px' }} className='signate_img'>
								<img
									src={
										imageData !== null
											? 'data:' + imageType + ';base64,' + encode(imageData)
											: ''
									}
									alt={'Company Seal'}
								/>
							</div>
						) : (
							''
						)}
					</div>
				</page>
				<ColoCofTermsConditionsNew
					isCrf={true}
					firstCaps={firstCaps}
					Signcss={Signcss}
					secondBold={secondBold}
					imageData={imageData}
					imageType={imageType}
					//CustomComponent={CustomComponent}
				/>
			</div>
		)
	} else {
		cofContentView = cafData ? (
			<div>
				<Center p={2} my={1}>
					<Spinner
						thickness='4px'
						speed='0.65s'
						emptyColor={chakraTheme.colors.secondary}
						color={chakraTheme.colors.brand[800]}
						size='md'
					/>
				</Center>
			</div>
		) : (
			<div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
				{' '}
				CAF Form not submitted yet.
			</div>
		)
	}

	return <div>{cofContentView}</div>
}
export default CloudCofForm
